import { faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { Load } from "../../components/Load";
import { ICidades, IEventos, IFilterEventos } from "../../global/types";
import { useQuery } from "../../hooks/useQuery";
import { api } from "../../services/api";
import { convertDateToBr, dateNow } from "../../utils/dateProvider";

import "./style.css";

type IParams = {
  idCidade?: string;
  nrPag?: number;
}

type IProps = {
  typePage: "Cidades" | "Todas"
}

export function EventosPorCidade({ typePage }: IProps) {
  const navigate = useNavigate();
  const query = useQuery();

  const { idCidade, nrPag } = useParams() as IParams;

  const [loadingCidades, setLoadingCidades] = useState(false);
  const [loadingEventos, setLoadingEventos] = useState(false);

  const [listCidades, setListCidades] = useState<ICidades[]>([]);
  const [listEventos, setListEventos] = useState<IEventos[]>([]);

  const [page, setPage] = useState(nrPag ? nrPag : 1);
  const [maxPage, setMaxPage] = useState(1);
  const [limitPage, setLimitPage] = useState(25);

  const [cidadeSelected, setCidadeSelected] = useState(idCidade ? idCidade : "");

  const loadCidades = async () => {
    setLoadingCidades(true);

    await api.get("cidades")
      .then((res) => {
        if (!res.data) return;

        setListCidades(res.data.result);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          alert(`Cidades: ${err.response?.data?.message}`);
        else
          alert("Erro ao tentar recuperar a lista de cidades. Tente novamente mais tarde.");
      })
      .finally(() => {
        setLoadingCidades(false);
      });
  }

  const loadEventos = async () => {
    setLoadingEventos(true);

    let cidadeFilter: string[] | undefined = undefined;
    if (cidadeSelected.length > 0)
      cidadeFilter = [cidadeSelected];

    let filters: IFilterEventos = {
      cidades: cidadeFilter,
      dataEventoDepois: dateNow()
    };

    const search = query.get("q")
    if (search !== null && typePage === "Todas")
      filters = { ...filters, nome: search };

    await api.post("eventos/list", {
      pesquisa: { ...filters },
      cursor: (limitPage * page) - limitPage,
      limit: limitPage,
      orderType: "ASC"
    })
      .then((res) => {
        if (!res.data) return;

        const newMaxPage = Math.ceil(res.data.total / limitPage);

        setListEventos(res.data.result);
        setMaxPage(newMaxPage < 1 ? 1 : newMaxPage);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          alert(`Eventos: ${err.response.data.message}`);
        else
          alert("Erro ao recuperar a lista de eventos. Tente novamente mais tarde.");
      })
      .finally(() => {
        setLoadingEventos(false);
      })
  }

  const handleSelectCidade = (id: string) => {
    setCidadeSelected(id);
    setPage(1);
  }

  const handlePagination = (p: number) => {
    setPage(p);
  }

  useEffect(() => {
    loadCidades();
  }, []);

  useEffect(() => {
    if (cidadeSelected.length > 3 || typePage === "Todas")
      loadEventos();
    else
      setListEventos([]);
  }, [page, cidadeSelected, typePage, query.get("q")]);

  return (
    <div id="pageEventosPorCidade">

      {typePage === "Cidades" && (
        <>
          <div className="sectionTitle">
            <p>
              CIDADES
            </p>
          </div>

          {loadingCidades && (
            <Load txtLoading="" />
          )}

          <div className="contentCidades">
            {!loadingCidades && listCidades.map((item) => (
              <div
                key={item.id}
                className={`cardCidade ${cidadeSelected === item.id ? "selected" : ""}`}
                onClick={() => handleSelectCidade(item.id)}
              >
                {item.nome}/{item.estado}
              </div>
            ))}
          </div>
        </>
      )}

      {loadingEventos && (
        <Load txtLoading="" />
      )}

      {!loadingEventos && listEventos.length > 0 && (
        <>
          <div className="sectionTitle" style={{ marginTop: "20px" }}>
            <p>
              EVENTOS
            </p>
          </div>

          <div className="contentEventos">
            {listEventos.map((item) => (
              <div className="cardEvento" key={`${item.id}`} onClick={() => navigate(`/evento/${item.id}`)}>
                <div className="poster" style={{ backgroundImage: `url(${api.defaults.baseURL}/eventoImg/${item.id}.jpg)` }} />
                <div className="info">
                  <p>{item.nome}</p>
                  <p>Data do Evento: {convertDateToBr(item.data)}h</p>
                </div>
              </div>
            ))}
          </div>

          <div className="pagination">
            {page !== 1 && (
              <FontAwesomeIcon icon={faAngleDoubleLeft} style={{ fontSize: "15px", cursor: "pointer" }} onClick={() => handlePagination(page - 1)} />
            )}
            <p>
              {page}/{maxPage}
            </p>
            {page !== maxPage && (
              <FontAwesomeIcon icon={faAngleDoubleRight} style={{ fontSize: "15px", cursor: "pointer" }} onClick={() => handlePagination(page + 1)} />
            )}
          </div>

        </>
      )}

    </div>
  )
}